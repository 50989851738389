type MySVGsProps = {
  index: number;
  fill: string;
  className?: any;
};

export const MySVGs = ({ index, fill, className }: MySVGsProps) => {
  return (
    <>
      {index === 1 && (
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <g id="Home Button">
            <path
              id="Vector"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.5171 4.54938C14.831 4.44742 15.169 4.44742 15.4829 4.54938C15.6036 4.58864 15.762 4.67296 16.0365 4.90601C16.3195 5.14617 16.6595 5.48526 17.1655 5.99121L22.8125 11.6382V20.2498C22.8125 21.3154 22.8117 22.0582 22.7645 22.6366C22.7181 23.2038 22.6317 23.5298 22.506 23.7767C22.2362 24.3059 21.806 24.7362 21.2769 25.0058C21.03 25.1316 20.704 25.2181 20.1366 25.2644C19.5584 25.3117 18.8156 25.3123 17.75 25.3123H12.25C11.1844 25.3123 10.4416 25.3117 9.86335 25.2644C9.296 25.2181 8.97005 25.1316 8.72315 25.0058C8.19395 24.7362 7.76368 24.3059 7.49405 23.7767C7.36825 23.5298 7.28182 23.2038 7.23547 22.6366C7.18822 22.0582 7.1875 21.3154 7.1875 20.2498V11.6382L12.8345 5.99121C13.3405 5.48524 13.6806 5.14617 13.9635 4.90601C14.238 4.67296 14.3964 4.58864 14.5171 4.54938ZM5.58642 10.5876L1.83708 14.3369C1.47097 14.7031 1.47097 15.2967 1.83708 15.6628C2.2032 16.0289 2.7968 16.0289 3.16291 15.6628L5.3125 13.5132V20.2498V20.2899C5.31248 21.3061 5.31248 22.1256 5.3667 22.7892C5.42253 23.4724 5.54047 24.0727 5.82341 24.6279C6.27281 25.5099 6.98991 26.2271 7.87192 26.6764C8.42718 26.9594 9.02737 27.0773 9.71066 27.1332C10.3743 27.1874 11.1938 27.1874 12.2098 27.1873H12.25H17.75H17.7901C18.8062 27.1874 19.6257 27.1874 20.2894 27.1332C20.9726 27.0773 21.5727 26.9594 22.1281 26.6764C23.0101 26.2271 23.7272 25.5099 24.1766 24.6279C24.4595 24.0727 24.5775 23.4724 24.6332 22.7892C24.6875 22.1256 24.6875 21.3061 24.6875 20.2901V20.2498V13.5132L26.8371 15.6628C27.2032 16.0289 27.7967 16.0289 28.1629 15.6628C28.529 15.2967 28.529 14.7031 28.1629 14.3369L18.4914 4.66538L18.4644 4.63848C17.9924 4.16634 17.5982 3.77226 17.25 3.47662C16.8867 3.16828 16.5122 2.91236 16.0622 2.76614C15.3719 2.54182 14.6281 2.54182 13.9377 2.76614C13.4877 2.91236 13.1132 3.16828 12.75 3.47662C12.4017 3.77226 12.0077 4.16634 11.5356 4.63847L11.5356 4.63848L11.5087 4.66538L5.58775 10.5863C5.58731 10.5867 5.58686 10.5872 5.58642 10.5876Z"
              fill={fill}
              fillOpacity="0.75"
            />
          </g>
        </svg>
      )}
      {index === 2 && (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <g id="Frame">
            <path
              id="Vector"
              d="M21 16V21H3V16H5V19H19V16H21ZM3 11H21V13H3V11ZM21 8H19V5H5V8H3V3H21V8Z"
              fill={fill}
              fillOpacity="0.75"
            />
          </g>
        </svg>
      )}
      {index === 3 && (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <g id="Frame">
            <path
              id="Vector"
              d="M4.00488 5.00293V19.0029H20.0049V5.00293H4.00488ZM3.00488 3.00293H21.0049C21.5572 3.00293 22.0049 3.45064 22.0049 4.00293V20.0029C22.0049 20.5552 21.5572 21.0029 21.0049 21.0029H3.00488C2.4526 21.0029 2.00488 20.5552 2.00488 20.0029V4.00293C2.00488 3.45064 2.4526 3.00293 3.00488 3.00293ZM12.0049 9.00293V6.00293L17.0049 11.0029H8.00488V9.00293H12.0049ZM7.00488 13.0029H16.0049V15.0029H12.0049V18.0029L7.00488 13.0029Z"
              fill={fill}
              fillOpacity="0.75"
            />
          </g>
        </svg>
      )}
      {index === 4 && (
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <g id="Frame">
            <path
              id="Vector"
              d="M8.75 5V25H21.25V5H8.75ZM7.5 2.5H22.5C23.1904 2.5 23.75 3.05965 23.75 3.75V26.25C23.75 26.9404 23.1904 27.5 22.5 27.5H7.5C6.80965 27.5 6.25 26.9404 6.25 26.25V3.75C6.25 3.05965 6.80965 2.5 7.5 2.5ZM15 21.25C15.6904 21.25 16.25 21.8096 16.25 22.5C16.25 23.1904 15.6904 23.75 15 23.75C14.3096 23.75 13.75 23.1904 13.75 22.5C13.75 21.8096 14.3096 21.25 15 21.25Z"
              fill={fill}
              fillOpacity="0.75"
            />
          </g>
        </svg>
      )}
      {index === 5 && (
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <g id="Frame">
            <path
              id="Vector"
              d="M25.1041 18.9998L26.6068 19.9014C26.9026 20.079 26.9986 20.4629 26.8211 20.7589C26.7683 20.8468 26.6946 20.9204 26.6068 20.9733L15.643 27.5514C15.2471 27.7889 14.7526 27.7889 14.3568 27.5514L3.39314 20.9733C3.09715 20.7956 3.00116 20.4118 3.17876 20.1158C3.23154 20.0278 3.30516 19.9541 3.39314 19.9014L4.89575 18.9998L14.9999 25.0623L25.1041 18.9998ZM25.1041 13.1248L26.6068 14.0264C26.9026 14.204 26.9986 14.5879 26.8211 14.8839C26.7683 14.9718 26.6946 15.0454 26.6068 15.0983L14.9999 22.0623L3.39314 15.0983C3.09715 14.9206 3.00116 14.5368 3.17876 14.2408C3.23154 14.1528 3.30516 14.0791 3.39314 14.0264L4.89575 13.1248L14.9999 19.1873L25.1041 13.1248ZM15.643 1.63565L26.6068 8.21385C26.9026 8.39144 26.9986 8.77535 26.8211 9.07134C26.7683 9.15932 26.6946 9.23294 26.6068 9.28572L14.9999 16.2498L3.39314 9.28572C3.09715 9.10813 3.00116 8.72422 3.17876 8.42823C3.23154 8.34025 3.30516 8.26663 3.39314 8.21385L14.3568 1.63565C14.7526 1.39814 15.2471 1.39814 15.643 1.63565ZM14.9999 4.16527L7.35904 8.74979L14.9999 13.3343L22.6408 8.74979L14.9999 4.16527Z"
              fill={fill}
              fillOpacity="0.75"
            />
          </g>
        </svg>
      )}
      {index === 6 && (
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <g id="Frame">
            <path
              id="Vector"
              d="M27.5 25V8.75L25 3.75H5L2.5 8.75441V25C2.5 25.6904 3.05965 26.25 3.75 26.25H26.25C26.9404 26.25 27.5 25.6904 27.5 25ZM5 11.25H25V23.75H5V11.25ZM6.545 6.25H23.455L24.705 8.75H5.29625L6.545 6.25ZM18.75 13.75H11.25V16.25H18.75V13.75Z"
              fill={fill}
              fillOpacity="0.75"
            />
          </g>
        </svg>
      )}
      {index === 7 && (
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <g id="Frame">
            <path
              id="Vector"
              d="M25 27.5H5C4.30965 27.5 3.75 26.9404 3.75 26.25V3.75C3.75 3.05965 4.30965 2.5 5 2.5H25C25.6904 2.5 26.25 3.05965 26.25 3.75V26.25C26.25 26.9404 25.6904 27.5 25 27.5ZM23.75 25V5H6.25V25H23.75ZM10 11.25H20V13.75H10V11.25ZM10 16.25H20V18.75H10V16.25Z"
              fill={fill}
              fillOpacity="0.75"
            />
          </g>
        </svg>
      )}
      {index === 8 && (
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <g id="Frame">
            <path
              id="Vector"
              d="M20 21.25V20H16.25V16.25H20V18.75H22.5V21.25H21.25V23.75H18.75V26.25H16.25V22.5H18.75V21.25H20ZM26.25 26.25H21.25V23.75H23.75V21.25H26.25V26.25ZM3.75 3.75H13.75V13.75H3.75V3.75ZM6.25 6.25V11.25H11.25V6.25H6.25ZM16.25 3.75H26.25V13.75H16.25V3.75ZM18.75 6.25V11.25H23.75V6.25H18.75ZM3.75 16.25H13.75V26.25H3.75V16.25ZM6.25 18.75V23.75H11.25V18.75H6.25ZM22.5 16.25H26.25V18.75H22.5V16.25ZM7.5 7.5H10V10H7.5V7.5ZM7.5 20H10V22.5H7.5V20ZM20 7.5H22.5V10H20V7.5Z"
              fill={fill}
              fillOpacity="0.75"
            />
          </g>
        </svg>
      )}
      {index === 9 && (
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <g id="Frame">
            <path
              id="Vector"
              d="M6.2561 11.2537C6.94647 11.2537 7.5061 11.8133 7.5061 12.5036C9.54957 12.5036 11.4294 13.2041 12.9186 14.3781L15.6311 14.3786C17.2969 14.3786 18.7935 15.1027 19.8235 16.2534L23.7561 16.2536C26.2465 16.2536 28.3966 17.7102 29.4013 19.8179C26.4453 23.7188 21.6585 26.2536 16.2561 26.2536C12.7678 26.2536 9.81833 25.4996 7.43 24.1812C7.25697 24.6606 6.79659 25.0036 6.2561 25.0036H2.5061C1.81575 25.0036 1.2561 24.444 1.2561 23.7536V12.5036C1.2561 11.8133 1.81575 11.2537 2.5061 11.2537H6.2561ZM7.50737 15.0036L7.5061 21.2811L7.5628 21.3218C9.80508 22.8972 12.7288 23.7536 16.2561 23.7536C20.0111 23.7536 23.5044 22.3091 26.05 19.8419L26.2161 19.6749L26.0671 19.5494C25.4871 19.0956 24.7756 18.8141 24.0124 18.7624L23.7561 18.7536L21.1168 18.7537C21.208 19.1558 21.2561 19.5741 21.2561 20.0036V21.2536H10.0061V18.7536L18.4936 18.7524L18.4506 18.6544C17.975 17.6624 16.9913 16.9602 15.8366 16.8853L15.6311 16.8786L11.9693 16.8785C10.8353 15.7218 9.25515 15.004 7.50737 15.0036ZM5.0061 13.7536H3.7561V22.5036H5.0061V13.7536ZM22.5061 6.25366C24.5771 6.25366 26.2561 7.9326 26.2561 10.0037C26.2561 12.0747 24.5771 13.7536 22.5061 13.7536C20.435 13.7536 18.7561 12.0747 18.7561 10.0037C18.7561 7.9326 20.435 6.25366 22.5061 6.25366ZM22.5061 8.75366C21.8158 8.75366 21.2561 9.3133 21.2561 10.0037C21.2561 10.694 21.8158 11.2537 22.5061 11.2537C23.1965 11.2537 23.7561 10.694 23.7561 10.0037C23.7561 9.3133 23.1965 8.75366 22.5061 8.75366ZM13.7561 2.50366C15.8271 2.50366 17.5061 4.1826 17.5061 6.25366C17.5061 8.32472 15.8271 10.0037 13.7561 10.0037C11.685 10.0037 10.0061 8.32472 10.0061 6.25366C10.0061 4.1826 11.685 2.50366 13.7561 2.50366ZM13.7561 5.00366C13.0658 5.00366 12.5061 5.5633 12.5061 6.25366C12.5061 6.94401 13.0658 7.50366 13.7561 7.50366C14.4465 7.50366 15.0061 6.94401 15.0061 6.25366C15.0061 5.5633 14.4465 5.00366 13.7561 5.00366Z"
              fill={fill}
              fillOpacity="0.75"
            />
          </g>
        </svg>
      )}
      {index === 10 && (
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={className}
        >
          <g id="More Button">
            <path
              id="Vector"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M4.58325 20C4.58325 21.1506 5.51599 22.0833 6.66659 22.0833C7.81719 22.0833 8.74992 21.1506 8.74992 20C8.74992 18.8493 7.81719 17.9166 6.66659 17.9166C5.51599 17.9166 4.58325 18.8493 4.58325 20ZM6.66659 24.5833C4.13529 24.5833 2.08325 22.5313 2.08325 20C2.08325 17.4686 4.13529 15.4166 6.66659 15.4166C9.19789 15.4166 11.2499 17.4686 11.2499 20C11.2499 22.5313 9.19789 24.5833 6.66659 24.5833Z"
              fill={fill}
              fillOpacity="0.75"
            />
            <path
              id="Vector_2"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.9167 20C17.9167 21.1506 18.8494 22.0833 20.0001 22.0833C21.1507 22.0833 22.0834 21.1506 22.0834 20C22.0834 18.8493 21.1507 17.9166 20.0001 17.9166C18.8494 17.9166 17.9167 18.8493 17.9167 20ZM20.0001 24.5833C17.4687 24.5833 15.4167 22.5313 15.4167 20C15.4167 17.4686 17.4687 15.4166 20.0001 15.4166C22.5314 15.4166 24.5834 17.4686 24.5834 20C24.5834 22.5313 22.5314 24.5833 20.0001 24.5833Z"
              fill={fill}
              fillOpacity="0.75"
            />
            <path
              id="Vector_3"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M31.25 20C31.25 21.1506 32.1827 22.0833 33.3333 22.0833C34.484 22.0833 35.4167 21.1506 35.4167 20C35.4167 18.8493 34.484 17.9166 33.3333 17.9166C32.1827 17.9166 31.25 18.8493 31.25 20ZM33.3333 24.5833C30.802 24.5833 28.75 22.5313 28.75 20C28.75 17.4686 30.802 15.4166 33.3333 15.4166C35.8647 15.4166 37.9167 17.4686 37.9167 20C37.9167 22.5313 35.8647 24.5833 33.3333 24.5833Z"
              fill={fill}
              fillOpacity="0.75"
            />
          </g>
        </svg>
      )}
      {index === 11 && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.4375 15C3.4375 15.863 4.13705 16.5625 5 16.5625C5.86295 16.5625 6.5625 15.863 6.5625 15C6.5625 14.137 5.86295 13.4375 5 13.4375C4.13705 13.4375 3.4375 14.137 3.4375 15ZM5 18.4375C3.10153 18.4375 1.5625 16.8985 1.5625 15C1.5625 13.1015 3.10153 11.5625 5 11.5625C6.89848 11.5625 8.4375 13.1015 8.4375 15C8.4375 16.8985 6.89848 18.4375 5 18.4375Z"
            fill={fill}
            fillOpacity="0.75"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.4375 15C13.4375 15.863 14.137 16.5625 15 16.5625C15.863 16.5625 16.5625 15.863 16.5625 15C16.5625 14.137 15.863 13.4375 15 13.4375C14.137 13.4375 13.4375 14.137 13.4375 15ZM15 18.4375C13.1015 18.4375 11.5625 16.8985 11.5625 15C11.5625 13.1015 13.1015 11.5625 15 11.5625C16.8985 11.5625 18.4375 13.1015 18.4375 15C18.4375 16.8985 16.8985 18.4375 15 18.4375Z"
            fill={fill}
            fillOpacity="0.75"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.4375 15C23.4375 15.863 24.137 16.5625 25 16.5625C25.863 16.5625 26.5625 15.863 26.5625 15C26.5625 14.137 25.863 13.4375 25 13.4375C24.137 13.4375 23.4375 14.137 23.4375 15ZM25 18.4375C23.1015 18.4375 21.5625 16.8985 21.5625 15C21.5625 13.1015 23.1015 11.5625 25 11.5625C26.8985 11.5625 28.4375 13.1015 28.4375 15C28.4375 16.8985 26.8985 18.4375 25 18.4375Z"
            fill={fill}
            fillOpacity="0.75"
          />
        </svg>
      )}
      {index === 12 && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={className}
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
        >
          <path
            d="M17.6696 10.7092C17.182 10.2259 16.3949 10.2294 15.9117 10.717C15.4284 11.2046 15.4319 11.9916 15.9195 12.4748L18.8413 15.3707C20.0271 16.546 20.8405 17.3548 21.3909 18.0401C21.9256 18.7059 22.109 19.1335 22.1578 19.5165C22.1884 19.7569 22.1884 20.0002 22.1578 20.2407C22.109 20.6237 21.9256 21.0513 21.3909 21.7171C20.8405 22.4024 20.0271 23.2113 18.8413 24.3865L15.9195 27.2823C15.4319 27.7656 15.4284 28.5527 15.9117 29.0403C16.3949 29.5278 17.182 29.5313 17.6696 29.0481L20.644 26.1C21.7643 24.9898 22.6798 24.0822 23.3292 23.2736C24.0045 22.4329 24.4933 21.5795 24.6239 20.5551C24.681 20.1058 24.681 19.6514 24.6239 19.2021C24.4933 18.1777 24.0045 17.3243 23.3292 16.4835C22.6798 15.6749 21.7643 14.7675 20.644 13.6572L17.6696 10.7092Z"
            fill={fill}
          />
        </svg>
      )}
    </>
  );
};
